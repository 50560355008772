import PropTypes from 'prop-types';

const StagePropType = PropTypes.shape({
  /**
   * Stage id, in algolia can be found as ObjectId
   */
  id: PropTypes.number,
  /**
   * URL that will redirected by the card
   */
  stage_link: PropTypes.string,
  /**
   * Path of the image shown in the card
   */
  product_thumb: PropTypes.string,
  /**
   * Path of the preview shown in the card, relative to the domain
   */
  preview_image_path: PropTypes.string,
  /**
   * Path of the preview shown in the card in the s3 bucket
   */
  preview_image_path_s3: PropTypes.string,
  /**
   * Title and alternative text shown in the card
   */
  seo_title: PropTypes.string,
  /**
     * Template type of the card. Each number is related to a template type
      - 13: 'image'
      - 15: 'video'
      - 16: 'bundle'
      - 18: 'expressVideo'
     */
  image_type_id: PropTypes.oneOf([13, 15, 16, 18]),
  /**
   * If this value is true, the Free ribbon is visible
   */
  is_free: PropTypes.bool,
  is_favorite: PropTypes.bool,
});

export default StagePropType;
