import {useEffect} from 'react';
import PropTypes from 'prop-types';
import StagePropType from './ProductType';
import ProductImage from './ProductImage';
import TemplateTagContainer from './TemplateTagContainer';
import ProductDescription from './ProductDescription';
import ProductAttributes from './ProductAttributes';
import {trackEvent} from '../../../lib/analytics';
import {getStageTags} from '../../../lib/utils/stageType';

export default function ProductCard({stage, className}) {
  useEffect(() => {
    const tags = getStageTags(stage);
    trackEvent('marketing:email:stageSeen', {label: tags});
  }, []);
  return (
    <div className={className}>
      <ProductImage product={stage} />
      <div className="grid-rows-[auto_1fr] rounded-b-md bg-cold-gray-200 px-2 py-4 md:grid md:grid-cols-[max-content_1fr] md:gap-x-6 md:p-4">
        <div className="col-span-2 mb-4">
          {stage.stage_description && <ProductDescription description={stage.stage_description} />}
        </div>
        <ProductAttributes product={stage} />
        <div className="col-start-2 overflow-hidden">
          <TemplateTagContainer product={stage} />
        </div>
      </div>
    </div>
  );
}

ProductCard.defaultProps = {
  className: '',
};
ProductCard.propTypes = {
  stage: StagePropType.isRequired,
  /** Styling for the card div */
  className: PropTypes.string,
};

export const PlasmicData = {
  name: 'ProductCard',
  props: {},
  importPath: './components/PDP/ProductCard',
  isDefaultExport: true,
};
