import PropTypes from 'prop-types';
import TagContainer from '../TagContainer';

const CATEGORY_TAGS = {
  mockups: ['device_tags', 'model_tags', 'ethnicity_tags', 'gender_tags', 'stage_tags'],
  'design templates': ['device_tags', 'stage_tags'],
  logos: ['device_tags', 'stage_tags'],
  videos: ['device_tags', 'stage_tags'],
};

const getTags = function getTags(data) {
  const category = data?.category_name?.toLowerCase();
  const properties = CATEGORY_TAGS[category];
  let tags = [];
  for (let index = 0; index < properties?.length && tags.length <= 10; index++) {
    const tagName = properties[index];
    tags = tags.concat(data[tagName]);
  }
  return tags.slice(0, 10);
};

/**
 * Creates a container showing Selection bubbles as tags
 */
export default function TemplateTagContainer({product}) {
  const tags = getTags(product);
  if (tags.length > 0) {
    return <TagContainer items={tags} title="Template Tags" />;
  }
  return null;
}

TemplateTagContainer.defaultProps = {
  product: {
    device_tags: [],
    model_tags: [],
    ethnicity_tags: [],
    gender_tags: [],
    stage_tags: [],
  },
};

TemplateTagContainer.propTypes = {
  /**
   * Template data
   */
  product: PropTypes.shape({
    /**
     * Category of the template
     */
    category_name: PropTypes.oneOf(['Mockups', 'Videos', 'Logos', 'Design Templates']),
    /**
     * Device Tags of the template
     */
    device_tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Model Tags of the template
     */
    model_tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Ethnicity Tags of the template
     */
    ethnicity_tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Gender Tags of the template
     */
    gender_tags: PropTypes.arrayOf(PropTypes.string),
    /**
     * Stage Tags of the template
     */
    stage_tags: PropTypes.arrayOf(PropTypes.string),
  }),
};
