import PropTypes from 'prop-types';
import Text from '../../UI/Atoms/Typography/Text';

export default function ProductDescription({title, description}) {
  return (
    <>
      <Text className="mb-2 text-cold-gray-900 lg:mb-4 lg:text-base" size="md" weight="bold">
        {title}
      </Text>
      <Text className="text-cold-gray-700 lg:text-base" size="md">
        {description}
      </Text>
    </>
  );
}

ProductDescription.defaultProps = {
  title: 'Template Description',
  description: 'Product Description',
};

ProductDescription.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
