import {useRouter} from 'next/router';
import StagePropType from './ProductType';
import ProductShareButton from '../Share/ProductShareButton';
import ProductFavoriteButton from '../ProductFavoriteButton';
import {ProductRibbon} from '../ProductRibbon';
import {shouldUseStagingData} from '../../../lib/algolia/client';
import useBucketsCdn from '../../../hooks/useBucketsCdn';

export default function ProductImage({product}) {
  const router = useRouter();
  const useStagingData = shouldUseStagingData(router);
  const preview = product.preview_image_path;
  const extension = preview?.substr(preview.lastIndexOf('.') + 1);
  const hasVideoPreview = extension?.includes('mp4') || extension?.includes('mov');
  const {stageThumbUrl} = useBucketsCdn();

  const addPreview = () => {
    function shouldShowVidPreview() {
      if (product.template_type === 'video') {
        if (product.category_name === 'Videos' || product.category_name === 'Mockups') {
          if (hasVideoPreview && preview !== null) {
            return true;
          }
        }
      }
      return false;
    }
    if (shouldShowVidPreview()) {
      const videoSrc = useStagingData
        ? window.location.origin + preview
        : `https://placeit.net${preview}`;
      return (
        <div className="contents">
          <video
            muted
            autoPlay
            playsInline
            loop
            src={videoSrc}
            width={product.product_thumb_width}
            height={product.product_thumb_height}
            poster={stageThumbUrl(product.product_thumb_path)}
          />
        </div>
      );
    }
    return (
      <img
        className="z-10 object-scale-down"
        alt={product.seo_title}
        src={stageThumbUrl(product.product_thumb_path)}
        height={product.product_thumb_height}
        width={product.product_thumb_width}
      />
    );
  };

  return (
    <div className="relative flex max-h-[437px] w-full content-center justify-center rounded-t-md bg-cold-gray-100 py-9 lg:max-h-[692px] lg:py-[72px]">
      <div className="absolute right-4 top-4 z-10 flex w-fit items-center gap-4">
        <div className="hidden gap-2.5 sm:flex">
          <ProductShareButton
            product={product}
            classNames={{
              trigger: 'bg-white h-[32px] w-[32px] hover:bg-cold-gray-400',
              icon: 'h-6 w-6 mx-auto',
              content: 'z-10',
            }}
          />
          <ProductFavoriteButton
            favorite={product.is_favorite}
            onClick={product.favorite_action}
            key={product.is_favorite}
          />
        </div>
        <ProductRibbon product={product} />
      </div>
      {addPreview()}
    </div>
  );
}

ProductImage.propTypes = {
  /**
   * Product data
   */
  product: StagePropType.isRequired,
};
