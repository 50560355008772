import PropTypes from 'prop-types';
import SeoTag from '../../SEO/SeoTag';
import RelatedTemplateContainer from './RelatedTemplateContainer';
import {Plasmic_017CtaBlock as CtaBlock} from '../../../plasmic_components/plasmic/placeit/Plasmic_017CtaBlock';
import Text from '../../UI/Atoms/Typography/Text';
import truncateText from '../../../lib/utils/truncateText';
import StagePropType from './ProductType';
import {Title} from '../ProductTitle';
import ProductButtonContainer from './ProductButtonContainer';
import {IcCloseXl as CloseIcon} from '../../UI/Atoms/Icons';

function ProductSeo({stage}) {
  const seoData = {
    title: `Placeit - ${stage.seo_title}`,
    description: truncateText(stage.stage_description, 150),
    canonical: `https://placeit.net${stage.stage_link}`,

    twitter: {
      cardType: 'summary_large_image',
      handle: '@placeitapp',
      site: '@placeitapp',
    },

    facebook: {
      appId: '586729674734199',
    },

    openGraph: {
      type: 'website',
      url: `https://placeit.net${stage.stage_link}`,
      images: [
        {
          url: `https://placeit.net/${stage.large_thumb_path}`,
          width: 640,
          height: 480,
        },
      ],
    },
    additionalMetaTags: [
      {
        httpEquiv: 'X-UA-Compatible',
        content: 'IE=edge',
      },
      {
        property: 'keywords',
        name: 'keywords',
        content: stage.name?.split(' ').join(','),
      },
    ],
    additionalLinkTags: [
      {
        rel: 'icon',
        href: '/favicon.png',
        type: 'image/png',
      },
    ],
  };

  return <SeoTag config={{...seoData}} />;
}
ProductSeo.propTypes = {
  stage: StagePropType.isRequired,
};

export default function Product({stage, children, className, cta, closeModalWithBack}) {
  return (
    <>
      <ProductSeo stage={stage} />
      <div className="flex flex-col p-4">
        <div className="sticky top-0 z-10 items-end md:grid md:grid-cols-[63.111%,1fr] md:gap-6 lg:grid-cols-[57.547%,1fr] xl:grid-cols-[55.242%,1fr] 2xl:grid-cols-[62.835%,1fr] 3xl:grid-cols-[58.267%,1fr]">
          <div
            tabIndex={0}
            onClick={closeModalWithBack}
            onKeyDown={closeModalWithBack}
            role="button"
            className="absolute -top-2 right-0 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-cold-gray-200 md:top-0 md:hidden"
          >
            <CloseIcon className="h-3 w-3 fill-cold-gray-700" />
          </div>
          <div className="block h-8 bg-white md:hidden" />
          <div className="flex flex-wrap items-center justify-between bg-white pb-4 sm:flex-nowrap">
            <Title product={{seo_title: stage.seo_title}} />
            <ProductButtonContainer product={stage} />
          </div>
          <div className="hidden h-full items-center justify-between md:flex">
            <Text className="mb-4 text-gray-600" size="2xl" weight="bold">
              Similar Templates
            </Text>
            <div
              tabIndex={0}
              onClick={closeModalWithBack}
              onKeyDown={closeModalWithBack}
              role="button"
              className="mb-4 hidden h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-cold-gray-200 md:flex"
            >
              <CloseIcon className="h-3 w-3 fill-cold-gray-700" />
            </div>
          </div>
        </div>
        <div className="no-scrollbar h-full overflow-auto ">
          <div className="block gap-6 md:grid md:grid-cols-[63.111%,1fr] md:grid-rows-[fit-content(0)] lg:grid-cols-[57.547%,1fr] xl:grid-cols-[55.242%,1fr] 2xl:grid-cols-[62.835%,1fr] 3xl:grid-cols-[58.267%,1fr]">
            <div className={`w-full ${className}`}>{children}</div>
            <div className="overflow-hidden">
              <Text className="my-4 block text-gray-600 md:hidden" size="2xl" weight="bold">
                Similar Templates
              </Text>
              <RelatedTemplateContainer type="modal" stage={stage} />
            </div>
          </div>
          {cta && <CtaBlock className="mb-6 mt-6" />}
        </div>
      </div>
    </>
  );
}

Product.defaultProps = {
  children: null,
  className: {
    product: '',
    related: '',
  },
  cta: true,
  closeModalWithBack: () => {},
};
Product.propTypes = {
  /**
   * Stage data
   */
  stage: StagePropType.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
  /** whether the cta should be shown at the bottom of the Product */
  cta: PropTypes.bool,
  closeModalWithBack: PropTypes.func,
};
