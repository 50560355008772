import PropTypes from 'prop-types';
import StagePropType from './ProductType';
import Text from '../../UI/Atoms/Typography/Text';
import {
  IcImage,
  IcVideo,
  IcExpressVideo,
  IcBundles,
  IcTransparent,
  IcDraggableElements,
} from '../../UI/Atoms/Icons';
import {productTypes} from '../../../lib/constants';

const PRODUCT_VARIANTS = {
  image: {
    icon: props => {
      return <IcImage {...props} />;
    },
    text: 'Image',
  },
  video: {
    icon: props => {
      return <IcVideo {...props} />;
    },
    text: 'Video',
  },
  expressVideo: {
    icon: props => {
      return <IcExpressVideo {...props} />;
    },
    text: 'Express Video',
  },
};

const getTypeIcon = type => {
  if (!type) {
    return null;
  }

  return PRODUCT_VARIANTS[productTypes[type]].icon;
};

export default function ProductAttributes({product, title}) {
  const type = product.image_type_id;
  const IconTag = getTypeIcon(type);

  product.hasTransparency = product.stage_tags?.includes('Transparent') || product.has_sibling;

  product.isBundle = product.bundle_tags?.length > 0;

  return type ? (
    <div className="flex flex-col gap-y-2 lg:gap-y-4">
      <Text as="h3" className="text-cold-gray-900 lg:text-base" size="md" weight="bold">
        {`${title}`}
        {product.sizes?.width && product.sizes?.height
          ? `  ·  ${product.sizes.width} x ${product.sizes.height} px`
          : ''}
      </Text>
      <div className="mb-4 flex flex-col gap-y-4 md:mb-0">
        <div className="flex w-full items-center">
          <IconTag className="h-6 w-6 lg:h-[32px] lg:w-[32px]" variant="multi" />
          <Text className="ml-2.5 text-cold-gray-800 lg:text-base" size="md">
            {PRODUCT_VARIANTS[productTypes[type]].text}
          </Text>
        </div>
        {product.hasTransparency && (
          <div className="flex w-full items-center">
            <IcTransparent className="h-6 w-6 lg:h-[32px] lg:w-[32px]" variant="multi" />
            <Text className="ml-2.5 text-cold-gray-800 lg:text-base" size="md">
              Transparent Background
            </Text>
          </div>
        )}
        {product.isInteractive && (
          <div className="flex w-full items-center">
            <IcDraggableElements className="h-6 w-6 fill-cold-gray-800 lg:h-[32px] lg:w-[32px] " />
            <Text className="ml-2.5 text-cold-gray-800 lg:text-base" size="md">
              Draggable Elements
            </Text>
          </div>
        )}
        {product.isBundle && (
          <div className="flex w-full items-center">
            <IcBundles className="h-6 w-6 lg:h-[32px] lg:w-[32px]" variant="multi" />
            <Text className="ml-2.5 text-cold-gray-800 lg:text-base" size="md">
              Bundle
            </Text>
          </div>
        )}
      </div>
    </div>
  ) : null;
}

ProductAttributes.displayName = 'ProductAttributes';
ProductAttributes.defaultProps = {
  title: 'Template Attributes',
};

ProductAttributes.propTypes = {
  /**
   * The text shown as title
   */
  title: PropTypes.string,
  product: StagePropType.isRequired,
};
