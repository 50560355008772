import {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {productTypes} from '../../../lib/constants';
import Product from './Product';
import ProductCard from './ProductCard';
import getProductData from '../../../lib/placeit/services/stages';
import {PDP_OPENED, PDP_CLOSED, trackEvent} from '../../../lib/analytics';
import StageRecord from '../../types/StageRecord';
import {IcChevronLeft, IcChevronRight} from '../../UI/Atoms/Icons';
import {shouldUseStagingData} from '../../../lib/algolia/client';

function NavigationArrow({clickHandler, position}) {
  const STYLES = {
    left: 'left-0 rounded-r-xl',
    right: 'right-0 rounded-l-xl',
  };
  return (
    <button
      className={`absolute top-1/2 z-20 h-24 bg-white drop-shadow hover:bg-cold-gray-200 ${STYLES[position]}`}
      type="button"
      onClick={clickHandler}
    >
      {position === 'left' ? (
        <IcChevronLeft className="h-6 w-6 fill-cold-gray-800" />
      ) : (
        <IcChevronRight className="h-6 w-6 fill-cold-gray-800" />
      )}
    </button>
  );
}
NavigationArrow.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
};

let lastStageUrl;
let lastStateKey;

export default function PDPModal({items, openModal, favorites}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stageData, setStageData] = useState({name: ''});
  const [currentStageIndex, setCurrentStageIndex] = useState();
  const [modalContent, setModalContent] = useState(null);

  const getMetadataType = () => {
    if (window?.customPageMetadata?.pageType) return 'customPageMetadata';
    if (window?.pageMetadata?.pageType) return 'pageMetadata';
    return '';
  };

  const getPageType = () => {
    const windowMetadataType = getMetadataType();
    if (windowMetadataType !== '') return window[windowMetadataType].pageType;
    return '';
  };

  const [originalPageType, setOriginalPageType] = useState('');

  const setMetadata = value => {
    const windowMetadataType = getMetadataType();
    if (windowMetadataType !== '') {
      Object.assign(window[windowMetadataType], {
        pageType: value,
      });
    }
  };

  useEffect(() => {
    setOriginalPageType(getPageType());
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setMetadata('PDP Modal');
    } else if (originalPageType !== '') {
      setMetadata(originalPageType);
    }
  }, [isModalOpen]);

  if (process.env.NODE_ENV !== 'test' && process.env.STORYBOOK_ENV !== 'test') {
    Modal.setAppElement('#__next');
  }
  const freezeBody = () => {
    document.body.classList.add('overflow-hidden');
    modalContent.addEventListener('keydown', keyHandler);
  };
  const unfreezeBody = () => {
    document.body.classList.remove('overflow-hidden');
    modalContent.removeEventListener('keydown', keyHandler);
    setModalContent(null);
  };

  const router = useRouter();
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModalWithBack = () => {
    trackEvent(PDP_CLOSED, {stage_id: stageData.id});
    return router.back();
  };

  const useStagingData = shouldUseStagingData(router);

  const prepareData = stageId => {
    const stageIndex = items.findIndex(hit => hit.id === stageId);
    const stage = items[stageIndex];
    const templateId =
      typeof stage.smart_template_id === 'string'
        ? stage.smart_template_id
        : stage.smart_template_id[0];
    getProductData(templateId, productTypes[stage.image_type_id], useStagingData).then(data => {
      setStageData({...stage, ...data});
      setIsModalOpen(true);
      trackEvent(PDP_OPENED, {stage_id: stage.id});
      const pageViewProperties = {stage_id: stage.id};
      trackEvent('page_view', pageViewProperties);
    });
  };

  openModal(openEvent => {
    openEvent.preventDefault();
    openEvent.stopPropagation();
    const origHref = window.location.href;
    const stageId = Number(openEvent.currentTarget.dataset.id);
    const stageIndex = items.findIndex(hit => hit.id === stageId);
    const stage = items[stageIndex];
    setCurrentStageIndex(stageIndex);
    setStageData(stage);
    const pdpLink =
      openEvent.currentTarget?.getAttribute && openEvent.currentTarget.getAttribute('href');
    if (!pdpLink) {
      throw new Error('no PDP link');
    }

    if (pdpLink === lastStageUrl && window.history.state.key === lastStateKey) {
      window.history.forward();
      return;
    }

    // handles router changes
    router.events.on('routeChangeStart', function rcc(url) {
      if (/\/c\/[\w-]+\/stages/.test(url)) {
        lastStageUrl = url;
        lastStateKey = window.history.state.key;
        prepareData(stageId);
        const event = new CustomEvent('route-or-history-change', {detail: {url, stageId}});
        window.dispatchEvent(event);
      }
      router.events.off('routeChangeStart', rcc);
    });

    // handles browser history changes
    router.beforePopState(({url, as}) => {
      if (/\/c\/[\w-]+\/stages/.test(as)) {
        setIsModalOpen(true);
        const event = new CustomEvent('route-or-history-change', {detail: {url, stageId}});
        window.dispatchEvent(event);
      } else {
        closeModal();
        const event = new CustomEvent('route-or-history-change', {detail: {url, stageId: false}});
        window.dispatchEvent(event);
      }
    });
    router.push(origHref, pdpLink, {shallow: true});
    return false;
  });
  useEffect(() => {
    if (stageData.id) {
      const stage = items.find(hit => hit.id === stageData.id);
      setStageData({...stage, sizes: stageData.sizes, isInteractive: stageData.isInteractive});
    }
  }, [favorites]);

  const keyHandler = event => {
    const keyPressed = event && event.keyCode;
    if (keyPressed === 37 && currentStageIndex > 0) {
      prevStage();
    } else if (keyPressed === 39 && currentStageIndex + 1 < items.length) {
      nextStage();
    }
  };

  const prevStage = () => {
    const origHref = window.location.href;
    const newStageIndex = currentStageIndex - 1;
    const newStage = items[newStageIndex];
    setCurrentStageIndex(newStageIndex);
    prepareData(newStage.id);
    router.replace(origHref, newStage.stage_link, {shallow: true});
  };
  const nextStage = () => {
    const origHref = window.location.href;
    const newStageIndex = currentStageIndex + 1;
    const newStage = items[newStageIndex];
    setCurrentStageIndex(newStageIndex);
    prepareData(newStage.id);
    router.replace(origHref, newStage.stage_link, {shallow: true});
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onAfterOpen={freezeBody}
      onAfterClose={unfreezeBody}
      onRequestClose={closeModalWithBack}
      className="absolute flex h-auto max-h-[90%] w-[90%] justify-center overflow-auto rounded-xl bg-white p-0 outline-none active:border-0 lg:max-h-[92%] lg:w-[80%]"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 z-10 justify-center flex items-center"
      contentRef={node => setModalContent(node)}
      key={stageData.id}
    >
      {currentStageIndex > 0 && <NavigationArrow clickHandler={prevStage} position="left" />}
      <Product
        stage={stageData}
        className="mx-auto"
        type="modal"
        cta={false}
        closeModalWithBack={closeModalWithBack}
      >
        <ProductCard stage={stageData} />
      </Product>
      {currentStageIndex + 1 < items.length && (
        <NavigationArrow clickHandler={nextStage} position="right" />
      )}
    </Modal>
  );
}
PDPModal.defaultProps = {
  favorites: [],
};
PDPModal.propTypes = {
  items: PropTypes.arrayOf(StageRecord).isRequired,
  openModal: PropTypes.func.isRequired,
  /** Array containing ids for favorited stages */
  favorites: PropTypes.arrayOf(PropTypes.number),
};
