import {useRouter} from 'next/router';
import {useInstantSearch} from 'react-instantsearch';
import StagePropType from './ProductType';
import BaseButton from '../../UI/Molecules/BaseButton';
import {IcEdit} from '../../UI/Atoms/Icons';
import ProductShareButton from '../Share/ProductShareButton';
import ProductFavoriteButton from '../ProductFavoriteButton';
import {PDP_EDIT, trackEvent} from '../../../lib/analytics';

export default function ProductButtonContainer({product}) {
  const router = useRouter();
  const {results: searchResultsData} = useInstantSearch();

  const handleClick = event => {
    const editorLink = getEnrichedEditorLink(product.editor_link, searchResultsData);

    event.preventDefault();
    // send event info to analytics
    trackEvent(PDP_EDIT, {stageID: product.id, stageTags: product.stage_tags?.join()});
    router.push(editorLink);
  };

  const getEnrichedEditorLink = (editorLink, searchResults) => {
    if (!searchResults || !searchResults.nbHits) {
      return `${product.stage_link}/editor`;
    }

    const editorLinkParamsString = editorLink.split('?')[1];
    const editorLinkParams = new URLSearchParams(editorLinkParamsString);
    editorLinkParams.set('IndexName', searchResults.index);

    if (searchResults.queryID && searchResults.query) {
      editorLinkParams.set('queryId', searchResults.queryID);
    }

    return `${product.stage_link}/editor?${editorLinkParams}`;
  };

  return (
    <div className="mt-2 flex h-fit w-full items-center justify-between gap-4 sm:ml-4 sm:mt-0 sm:w-auto lg:ml-6 lg:gap-6">
      <div className="flex gap-2.5 sm:hidden">
        <ProductShareButton
          product={product}
          classNames={{
            trigger: 'bg-cold-gray-200 p-2 h-[38px] w-[38px]',
            icon: 'h-6 w-6',
            content: 'z-20',
          }}
        />
        <ProductFavoriteButton
          favorite={product.is_favorite}
          onClick={product.favorite_action}
          key={product.is_favorite}
        />
      </div>
      <a href={product.stage_link} className="w-full sm:w-auto " rel="nofollow">
        <BaseButton
          className="w-full items-center gap-x-[5px] px-4 py-[9px] text-sm sm:w-auto sm:px-[40.5px] sm:py-4 md:px-[41px] lg:px-[33.5px] lg:py-[12px] xl:px-[47.5px] 2xl:px-[48px] 3xl:px-[59px]"
          size="custom"
          variant="primaryRegular"
          leftIcon={<IcEdit className="h-[18px] w-[18px]" />}
          onClick={handleClick}
        >
          Edit
        </BaseButton>
      </a>
    </div>
  );
}

ProductButtonContainer.propTypes = {
  /**
   * Product data
   */
  product: StagePropType.isRequired,
};
