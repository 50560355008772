import StagePropType from './ProductType';
import ProductRelatedTemplates from '../RelatedTemplates/ProductRelatedTemplates';

export default function RelatedTemplateContainer({stage}) {
  const {
    category_name: categoryName,
    model_tags: modelTag,
    device_tags: deviceTag,
    stage_tags: stageTag,
    id: pdpId,
  } = stage;

  const pdpTags = {
    categoryName,
    modelTag,
    deviceTag,
    stageTag,
    pdpId,
  };

  return <ProductRelatedTemplates pdpTags={pdpTags} id={pdpId} type="modal" />;
}

RelatedTemplateContainer.propTypes = {
  stage: StagePropType.isRequired,
};
